<template>
  <div>
    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Educação e desempenho escolar"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  Na situação vivenciada em consequência do rompimento da
                  barragem de Fundão, dados educacionais disponíveis no site do
                  Instituto Nacional de Estudos e Pesquisas Educacionais Anísio
                  Teixeira (Inep) possibilitam reflexões a respeito do processo
                  de escolarização de crianças e adolescentes atingidas.
                  Informações do Censo da Educação Básica, indicadores
                  educacionais e do Sistema Nacional de Avaliação da Educação
                  Básica (Saeb) nos ajudam a analisar as escolas no município de
                  Mariana-MG, entre os anos de 2011 e 2020.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_21.png" />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Considerações metodológicas e escolas selecionadas"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  Os dados do Censo Escolar<sup>1</sup> são disponibilizados
                  pelo Instituto Brasileiro de Geografia e Estatística (IBGE), a
                  partir dos distritos por ele classificados. Entre eles,
                  encontra-se o distrito de Santa Rita Durão, ao qual pertence o
                  subdistrito de Bento Rodrigues; também o distrito de Monsenhor
                  Horta, do qual faz parte o subdistrito de Paracatu de Baixo.
                </p>
                <p>
                  Ressalta-se que as comunidades de Bento Rodrigues e Paracatu
                  de Baixo são as mais representativas entre as quatro aqui
                  analisadas em relação ao que o IBGE considera como área ou
                  zona rural. Basicamente, são áreas que não foram incluídas no
                  perímetro urbano por lei municipal. Caracterizam-se pelo uso
                  rústico do solo, por abranger grandes extensões de terra e
                  possuir baixa densidade habitacional, incluem campos,
                  florestas, lavouras, pastos, etc. Tal fato é importante de ser
                  salientado tendo em vista o deslocamento compulsório das
                  comunidades de Bento Rodrigues e Paracatu de Baixo para a área
                  urbana de Mariana.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  Tomamos como referência o texto de Wanderley
                  (2015)<sup>2</sup>, e então selecionamos as escolas nos
                  distritos de Mariana-MG, que mais sofreram consequências
                  danosas pelo rompimento da barragem de Fundão: os distritos,
                  tal como classificados pelo IBGE, de Monsenhor Horta e de
                  Santa Rita do Durão. As duas escolas existentes no distrito de
                  Camargos também foram incluídas, já que atendiam parte dos
                  alunos da comunidade de Bento Rodrigues.
                </p>
                <p>
                  As escolas selecionadas na zona rural foram: Escola Municipal
                  Bento Rodrigues (distrito de Camargos); Escola Municipal de
                  Bicas (distrito de Camargos); Escola Municipal Paracatu de
                  Baixo (distrito de Monsenhor Horta); Escola Municipal de Ponte
                  do Gama (distrito de Monsenhor Horta); Escola Municipal
                  Prefeito Jadir Macedo (distrito de Monsenhor Horta). Já as
                  escolas selecionadas na área urbana foram: Escola Municipal de
                  Monsenhor Horta: Escola Estadual Cônego Braga (Monsenhor
                  Horta); Escola Municipal Sinho Machado (Santa Rita Durão).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Escolas e acesso à educação obrigatória"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  O número de escolas no município de Mariana foi reduzido ao
                  longo dos anos, considerando o período entre 2011 e 2020,
                  principalmente em áreas rurais. A situação verificada aponta
                  que um conjunto de 15 escolas que se encontravam paralisadas
                  em anos anteriores foi extinto em 2015, e não houve acréscimo
                  de escolas na rede pública até 2020. Áreas rurais foram as
                  mais prejudicadas pelo rompimento da barragem e também pela
                  extinção de escolas. Segundo o Censo Escolar, no município de
                  Mariana, não foram realizadas matrículas no ensino médio em
                  escolas rurais.
                </p>
                <p>
                  Essa realidade prejudica os jovens das comunidades rurais, que
                  são obrigados a procurar escolas em áreas urbanas para
                  completar sua formação. Arcando com os custos e dificuldades
                  de buscar a complementação de alguma etapa de ensino, os
                  jovens têm de se deslocar sozinhos para outras comunidades
                  onde existem escolas. Em outros casos, as famílias têm que
                  mudar toda a sua rotina, e a ausência do jovem no cotidiano da
                  comunidade pode afetar a produção familiar, a exemplo de
                  jovens que contribuem com sua força de trabalho no manejo dos
                  animais, no trato da roça e nas diversas atividades rurais;
                  auxílio na atividade doméstica, no cuidado com as crianças e
                  idosos, etc.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig1 = !showOverlayer.fig1"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig1" class="text-center">
            <img
              src="../../assets/conteudos_crianca_adolescente_22.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig1 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_22.png" />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig2 = !showOverlayer.fig2"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig2" class="text-center">
            <img
              src="../../assets/conteudos_crianca_adolescente_23.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig2 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_23.png" />
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Rendimento dos Estudantes: aprovação, reprovação e abandono escolar"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  As escolas selecionadas na presente análise, situadas em áreas
                  rurais de distritos atingidos em Mariana, mostram uma
                  diferença considerável no que diz respeito à redução de
                  aprovações e aumento de reprovações, quando comparadas com as
                  demais escolas do município.
                </p>
                <p>
                  Ao contrário do que se poderia esperar, o abandono dos estudos
                  não variou de maneira expressiva entre 2011 e 2020.
                  Entretanto, a reprovação cresceu muito no mesmo período,
                  sinalizando para uma exclusão interna ao sistema de ensino, ou
                  seja, demonstra a dificuldade dos alunos das comunidades
                  rurais em acompanhar ou aprender o que está sendo ensinado nas
                  escolas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig3 = !showOverlayer.fig3"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig3" class="text-center">
            <img
              src="../../assets/conteudos_crianca_adolescente_24.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig3 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_24.png" />

          <img
            class="mt-3"
            src="../../assets/conteudos_crianca_adolescente_25.png"
          />
          <span
            @click="showOverlayer.fig4 = !showOverlayer.fig4"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig4" class="text-center">
            <img
              src="../../assets/conteudos_crianca_adolescente_25.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig4 = false">
              fechar
            </v-btn>
          </v-overlay>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  Nas escolas das comunidades rurais, a quantidade de alunos
                  aprovados tende a ser menor do que nas demais escolas do
                  município, enquanto o número de reprovação nessas mesmas
                  escolas tende a ser maior. A comparação dos resultados
                  mostra-se mais radical especialmente entre os anos de 2015 e
                  2017, período em que vivem as consequências do rompimento da
                  barragem de Fundão.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Desempenho nos testes"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  A rede municipal de ensino do município de Mariana teve uma
                  piora nos indicadores de desempenho dos estudantes entre 2013
                  e 2019. Os resultados do Sistema de Avaliação da Educação
                  Básica (Saeb) mostram que o percentual de estudantes com
                  desempenho abaixo do esperado para o 5º e 9º ano nas
                  disciplinas de Língua Portuguesa e Matemática aumentou.
                  Lembramos que a rede municipal de ensino é a principal no
                  atendimento de estudantes na área rural para várias etapas do
                  ensino fundamental (entre o 1º e 9º ano, quando os estudantes
                  têm entre 6 e 14 anos de idade).
                </p>
                <p style="text-align: center">
                  <strong
                    >Autoria: Túlio Silva de Paula<sup>3</sup> e Pedro Paulo
                    Barros Gonçalves<sup>4</sup>
                  </strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig5 = !showOverlayer.fig5"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig5" class="text-center">
            <img
              width="100%"
              src="../../assets/conteudos_crianca_adolescente_26.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig5 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_26.png" />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig6 = !showOverlayer.fig6"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig6" class="text-center">
            <img
              width="100%"
              src="../../assets/conteudos_crianca_adolescente_27.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig6 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_27.png" />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig7 = !showOverlayer.fig7"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig7" class="text-center">
            <img
              width="100%"
              src="../../assets/conteudos_crianca_adolescente_28.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig7 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_28.png" />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig8 = !showOverlayer.fig8"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig8" class="text-center">
            <img
              width="100%"
              src="../../assets/conteudos_crianca_adolescente_29.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig8 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img src="../../assets/conteudos_crianca_adolescente_29.png" />
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> O Censo Escolar é a principal ferramenta para coletar
              informações sobre a educação básica e a mais importante pesquisa
              estatística educacional brasileira. Ele investiga atividades e
              costumes importantes para o conhecimento dos alunos de cada local
              ou região. Essas informações são essenciais para as políticas de
              educação básica e servem de orientação tanto para a destinação dos
              recursos entre estados e municípios, como para a criação de
              programas do governo federal.
            </p>
            <p>
              <sup>2</sup> WANDERLEY, Luiz Jardim. Indícios de Racismo Ambiental
              na Tragédia de Mariana: resultados preliminares e nota técnica.
              Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://www.ufjf.br/poemas/files/2014/07/Wanderley-2015-Ind%C3%ADcios-de-Racismo-Ambiental-na-Trag%C3%A9dia-de-Mariana.pdf"
                target="_blank"
                >https://www.ufjf.br/poemas/files/2014/07/Wanderley-2015-Ind%C3%ADcios-de-Racismo-Ambiental-na-Trag%C3%A9dia-de-Mariana.pdf</a
              >. Acesso em: 22 fev. 2022.
            </p>
            <p>
              <sup>3</sup> Doutor em Educação (UFMG), Mestre em Sociologia
              (UFMG), Graduado em Ciências Sociais (UFMG). Assessor Técnico na
              ATI Mariana.
            </p>
            <p>
              <sup>4</sup> Mestre em Planejamento Urbano e Regional
              (IPPUR/UFRJ), Graduado em Sociologia (Unitau) e Relações
              Internacionais (PUC Minas). Assessor Técnico na ATI Mariana.
            </p>
          </div></v-col
        ></v-row
      >
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
  data() {
    return {
      showOverlayer: {
        fig1: false,
        fig2: false,
        fig3: false,
        fig4: false,
        fig5: false,
        fig6: false,
        fig7: false,
        fig8: false,
      },
    };
  },
};
</script>

<style scoped>
.section__mb {
  padding-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
